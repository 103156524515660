import React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Kosten = [
    {
        id: 1,
        title: "Private Krankenkassen",
        definition: "In der Regel übernehmen private Krankenversicherungen nach Bewilligung durch einen Gutachter die Kosten für eine Psychotherapie. Der jeweilige Versicherungsvertrag regelt die Anzahl der Sitzungen und die Höhe der Kostenübernahme. Bitte setzen Sie sich frühzeitig mit Ihrer Kasse in Verbindung, um sich über die genauen Leistungen zu informieren und notwendige Formulare anzufordern. Die probatorischen Sitzungen (bis zu fünf Sitzungen zu Beginn der Psychotherapie) werden von den privaten Krankenkassen in der Regel ohne Bewilligung genehmigt und bilden die Grundlagen für die Beantragung der Psychotherapie. Meine Kosten richten sich nach der Gebührenordnung für Psychotherapeuten (GOP), je nach Versicherungsvertrag muss ein Teil des Honorars selbst übernommen werden."
    },
    {
        id: 2,
        title: "Beihilfe",
        definition: "Die Beihilfe sowie private Krankenversicherungen mit Beihilfeergänzung erstatten in der Regel die vollen Kosten bei Vorliegen einer behandlungswürdigen psychischen Erkrankung. Bitte wenden Sie sich an Ihre Beihilfestelle und lassen Sie sich die notwendigen Formulare zusenden."
    },
    {
        id: 3,
        title: "Gesetzliche Krankenkassen",
        definition: "Eine Abrechnung mit den gesetzlichen Krankenkassen ist in meiner Privatpraxis lediglich über das sogenannte „Kostenerstattungsverfahren“ möglich. Informieren Sie sich gerne über die genauen Bedingungen bei Ihrer gesetzlichen Versicherung. Einige Fragen werden auch hier beantwortet: https://www.therapie.de/psyche/info/fragen/wichtigste-fragen/psychotherapie-kostenerstattung/. Gerne können Sie mich bei weiteren Fragen persönlich kontaktieren."
    },
    {
        id: 4,
        title: "Selbstzahler",
        definition: "Natürlich haben Sie die Möglichkeit, die Kosten der Therapie selbst zu übernehmen. Die Kosten richten sich nach der Gebührenordnung für Psychotherapeuten (GOP). Ihre Krankenversicherung erhält hierbei keine Information über die Aufnahme der Psychotherapie."
    },
    {
        id: 5,
        title: "Coaching",
        definition: "Bei Coaching findet keine Kostenübernahme durch die Krankenkasse statt. Die Kosten orientieren sich am aktuellen Satz der Gebührenordnung für Psychotherapeuten und werden zu Beginn der gemeinsamen Arbeit festgelegt."
    },
]

function isEven(value){
    if (value%2 === 0)
        return true;
    else
        return false;
}

const KostenPage = () => (
  <Layout>
    <Seo
      title="Kosten | Praxis für Psychotherapie München | Verhaltenstherapie"
      description="Kostenübernahme der Psychotherapie, Abrechnung über private Krankenversicherungen & Beihilfe, Selbstzahler. Privatpraxis: Im Kostenerstattungsverfahren Übernahme durch geseztliche Krankenversicherung möglich"
    />

    <div className="relative">
        <div className="w-full h-96">
            <StaticImage className="w-full h-full" src="../assets/images/hero-ueber-mich.jpg" alt="" />
            <div className="absolute top-0 w-full h-full opacity-30 bg-gradient-to-r from-transparent via-black to-transparent"></div>
            <div className="absolute top-0 flex flex-wrap items-center w-full h-full place-items-center">
                <div className="w-full text-center text-white">
                    <h1 className="mb-4 lg:text-4xl">Kosten</h1>
                    {/* <div className="px-4 sm:text-lg">"Vertrauensvolle Zusammenarbeit auf Augenhöhe ist mir wichtig."</div> */}
                </div>
            </div>
        </div>
    </div>
    <div className="my-12 boxed max-w-prose generic-page">
      <p>Die Psychotherapiekosten (auch Kosten für ein Erstgespräch) richten sich nach der GOP (Gebührenordnung für Psychotherapeuten). Für eine Einzelbehandlung (50 Minuten) wird ein Gebührenrahmen zwischen dem 2,8-fachen (122,24 Euro) und dem 3,5-fachen (153,02 Euro) Steigerungssatz vereinbart. Zudem können zusätzliche Kosten für weitere Diagnostik, Befundberichte und andere Mehraufwendungen entstehen, die auf der Grundlage der GOP abgerechnet werden. Die Therapiekosten werden am Monatsende dem/der Patient/in in Rechnung gestellt. Informieren Sie sich bei Ihrem Versicherungsträger über die (anteilige) Kostenübernahme einer Psychotherapie. Gerne unterstütze ich Sie bei Fragen und der Beantragung einer Psychotherapie.</p>
    </div>
    <div className="my-12 generic-page boxed">
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="">
        <dl>
            {Kosten.map((item) => (
                <div key={item.id} className={(!isEven(item.id) ? "bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 auto-cols-min sm:gap-4 sm:px-6" : "px-4 py-5 sm:grid sm:grid-cols-3 auto-cols-min sm:gap-4 sm:px-6")}>
                    <dt className="text-sm font-medium text-gray-500">{item.title}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 max-w-prose">{item.definition}</dd>
                </div>
            ))}

          {/* <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Attachments</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ul className="border border-gray-200 divide-y divide-gray-200 rounded-md">
                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="flex items-center flex-1 w-0">
                    <PaperClipIcon className="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                    <span className="flex-1 w-0 ml-2 truncate">resume_back_end_developer.pdf</span>
                  </div>
                  <div className="flex-shrink-0 ml-4">
                    <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                      Download
                    </a>
                  </div>
                </li>
                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="flex items-center flex-1 w-0">
                    <PaperClipIcon className="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                    <span className="flex-1 w-0 ml-2 truncate">coverletter_back_end_developer.pdf</span>
                  </div>
                  <div className="flex-shrink-0 ml-4">
                    <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                      Download
                    </a>
                  </div>
                </li>
              </ul>
            </dd>
          </div> */}
        </dl>
      </div>
    </div>
    </div>
  </Layout>
)

export default KostenPage